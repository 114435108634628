<template>
    <div>
        <ConversionSyncDialog @handleSubmit="createConversionSync" @handleCancel="conversionSyncDialogIsVisible = false"
            v-if="conversionSyncDialogIsVisible" />
        <div class="page-header">
            <h3>编辑应用</h3>
            <div class="action">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <AppForm v-model="data" @handleSubmit="handleSubmit" />
        <div class="page-header">
            <h3>同步信息</h3>
            <div class="action">
                <button @click="conversionSyncDialogIsVisible = true" class="btn btn-light">添加同步</button>
            </div>
        </div>
        <div class="table" v-if="conversionSyncs.length > 0">
            <table>
                <tr>
                    <th>转化类型</th>
                    <th>启用同步</th>
                    <th>oCPC推送</th>
                    <th width="136">最近同步时间</th>
                    <th width="136">创建时间</th>
                </tr>
                <tr v-for="(item, index) in conversionSyncs" :key="index">
                    <td>{{ item.conversion_type | conversionType }}</td>
                    <td>
                        <div class="form-switch"><input type="checkbox" v-model="item.is_enabled" value="1"
                                @change="handleItemEnabled(item, $event)"></div>
                    </td>
                    <td>
                        <div class="form-switch"><input type="checkbox" v-model="item.is_ocpc_pushed" value="1"
                                v-if="item.conversion_type !== 4" @change="handleItemOcpcPushed(item, $event)"></div>
                    </td>
                    <td>{{ item.synced_at }}</td>
                    <td>{{ item.created_at }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import AppForm from '../../components/AppForm.vue'
import ConversionSyncDialog from '../../components/ConversionSyncDialog.vue'

export default {
    name: 'Account',
    components: {
        AppForm,
        ConversionSyncDialog
    },
    data() {
        return {
            data: {},
            conversionSyncData: {},
            conversionSyncs: [],
            conversionSyncDialogIsVisible: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        fetchData() {
            this.startLoading()
            this.$api.post('app/info', { id: this.$route.params.id }).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                    this.fetchConversionSyncs()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        fetchConversionSyncs() {
            this.sum = null
            this.$api.post('conversion_sync/list', {
                account_id: this.$route.params.account_id,
                app_id: this.$route.params.id
            }).then(res => {
                if (res.data.success) {
                    this.conversionSyncs = res.data.data.list
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error.message)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleSubmit(data) {
            this.startLoading()
            this.$api.post('app/update', data).then(res => {
                alert(res.data.message)
                if (res.data.success) {
                    this.$router.back()
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        createConversionSync(type) {
            if (type === undefined || type <= 0) {
                alert('请选择转化类型。')
                return
            }
            this.startLoading()
            this.$api.post('conversion_sync/create', { app_id: this.$route.params.id, conversion_type: type }).then(res => {
                if (res.data.success) {
                    this.fetchConversionSyncs()
                } else {
                    this.finishLoading()
                    alert(res.data.message)
                }
            }).catch(error => {
                this.finishLoading()
                alert(error)
            }).finally(() => {
                this.conversionSyncDialogIsVisible = false
            })
        },
        handleItemEnabled(item, event) {
            this.startLoading()
            let newVal = event.target.checked ? 1 : 0
            this.$api.post('conversion_sync/update', { id: item.id, is_enabled: newVal }).then(res => {
                if (!res.data.success) {
                    item.is_enabled = newVal == 1 ? 0 : 1
                    alert(res.data.message)
                }
            }).catch(error => {
                item.is_enabled = newVal == 1 ? 0 : 1
                alert(error)
            }).finally(() => {
                this.finishLoading()
            })
        },
        handleItemOcpcPushed(item, event) {
            this.startLoading()
            let newVal = event.target.checked ? 1 : 0
            this.$api.post('conversion_sync/update', { id: item.id, is_ocpc_pushed: newVal }).then(res => {
                if (!res.data.success) {
                    item.is_ocpc_pushed = newVal == 1 ? 0 : 1
                    alert(res.data.message)
                }
            }).catch(error => {
                item.is_ocpc_pushed = newVal == 1 ? 0 : 1
                alert(error)
            }).finally(() => {
                this.finishLoading()
            })
        }
    }
}
</script>