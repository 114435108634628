<template>
    <div class="pagination" v-if="total_count">
        <span class="text">共 <b>{{ total_count }}</b> 条记录, 每页显示</span>
        <Selection size="s" :items="{ 10: '10条', 20: '20条', 50: '50条', 100: '100条' }" :top="true" width="80"
            v-model="localPageSize" @handleItemClick="handlePageSize">
        </Selection>
        <span class="text">当前在 <b>{{ current_page }}/{{
            total_page }}</b>
            页
        </span>
        <ul class="pages" v-if="total_page > 1">
            <li v-if="start_page > 1"><i @click="handlePage(1)">1</i></li>
            <li v-if="start_page > 1"><i @click="handlePage(previous_page)">...</i></li>
            <li v-for="(page, index) in pages" :class="{ active: page == current_page }" :key="index">
                <i @click="handlePage(page)">{{ page }}</i>
            </li>
            <li v-if="end_page < total_page - 1"><i @click="handlePage(next_page)">...</i></li>
            <li v-if="end_page < total_page"><i @click="handlePage(total_page)">{{ total_page }}</i></li>
        </ul>
    </div>
</template>

<script>
import Selection from './Selection.vue'

export default {
    name: 'Pagination',
    components: {
        Selection
    },
    props: {
        total_count: Number,
        page_size: {
            type: null, // 将 prop 类型设置为 any
            required: true
        },
        current_page: Number
    },
    data() {
        return {
            total_page: 0,
            start_page: 0,
            end_page: 0,
            pages: [],
            previous_page: 0,
            next_page: 0,
            previous_group: 0,
            next_group: 0,
            page_interval: 5,
            localPageSize: parseInt(this.page_size)
        }
    },
    watch: {
        total_count() {
            this.propsUpdated()
        },
        page_size(val) {
            this.localPageSize = parseInt(val)
            this.propsUpdated()
        },
        current_page() {
            this.propsUpdated()
        }
    },
    methods: {
        handlePage(page) {
            this.$emit("handlePage", page)
        },
        handlePageSize(pageSize) {
            this.$emit("handlePageSize", pageSize)
        },
        propsUpdated() {
            this.total_page = Math.ceil(this.total_count / this.localPageSize)
            let start_page = Math.floor((this.current_page - 1) / this.page_interval) * this.page_interval + 1
            if (start_page < this.page_interval) {
                start_page = 1
            }
            let end_page = start_page + (this.page_interval - 1)
            if (end_page >= this.total_page) {
                end_page = this.total_page
            }
            this.end_page = end_page
            let pages = []
            for (let i = start_page; i <= end_page; i++) {
                pages.push(i)
            }
            this.pages = pages
            this.start_page = start_page
            let previous_page = this.start_page - this.page_interval
            let next_page = this.start_page + this.page_interval
            if (previous_page < 1) {
                previous_page = 1
            }
            if (next_page > this.total_page) {
                next_page = this.total_page
            }
            this.previous_page = previous_page
            this.next_page = next_page
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/pagination.scss';
</style>