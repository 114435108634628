<template>
    <div>
        <div class="page-header">
            <h3>应用信息</h3>
            <div class="action">
                <router-link :to="`/accounts/${$route.params.account_id}/apps/new`" class="btn btn-light">添加应用</router-link>
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <div class="table" v-if="list.length > 0">
            <table>
                <tr>
                    <th>应用名称</th>
                    <th width="100">应用类型</th>
                    <th>Param1</th>
                    <th>Param2</th>
                    <th width="136">创建时间</th>
                    <th width="80"></th>
                </tr>
                <tr v-for="(item, index) in list" :key="index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.param1 }}</td>
                    <td>{{ item.param2 }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>
                        <router-link :to="`/accounts/${$route.params.account_id}/apps/${item.id}/edit`" class="btn btn-light btn-s">编辑</router-link>
                    </td>
                </tr>
            </table>
        </div>
        <div class="page-info">
            <Pagination :total_count="count" :current_page="query.page" :page_size="query.page_size"
                @handlePage="handlePage" @handlePageSize="handlePageSize"></Pagination>
        </div>
    </div>
</template>

<script>
import { formatDate } from '@/filters'
import { mapMutations } from 'vuex'

import Pagination from '../../components/Pagination.vue'

export default {
    name: 'Conversion',
    components: {
        Pagination
    },
    data() {
        return {
            query: {},
            list: [],
            count: 0
        }
    },
    mounted() {
        this.query = { ...this.getDefaultQuery(), ...this.$route.query }
        this.fetchList()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        getDefaultQuery() {
            let query = {
                account_id: this.$route.params.account_id,
                page: 1,
                page_size: 10
            }
            const now = new Date()
            query.end_date = query.start_date = formatDate(now)
            return query
        },
        handlePage(page) {
            this.query.page = page
            this.fetchList()
        },
        handlePageSize(pageSize) {
            this.query.page_size = pageSize
            this.fetchList()
        },
        fetchList() {
            this.startLoading()
            this.sum = null
            this.$api.post('app/list', this.query).then(res => {
                if (res.data.success) {
                    this.list = res.data.data.list
                    this.count = res.data.data.count
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error.message)
            }).finally(() => {
                this.finishLoading()
            })
        },
        onQuery() {
            this.handlePage(1)
        },
        onClear() {
            this.query = { ...this.getDefaultQuery(), ...this.$route.query }
            this.fetchList()
        }
    }
}
</script>