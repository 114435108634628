<template>
    <div
        :class="'selection' + (size ? ' selection-' + size : '') + (top ? ' selection-top' : '') + (styleClass ? ' ' + styleClass : '') + (width ? ' width-' + width : '')">
        <span class="arrow"></span>
        <input type="text"
            :class="'form-control' + (size ? ' form-control-' + size : '') + (!edit ? ' select' : '') + (isFocus ? ' active' : '')"
            v-model="inputValue" @focus="handleFocus" @blur="handleBlur" :placeholder="placeholder"
            :readonly="edit && !readonly ? false : true" @input="handleInput">
        <div class="items" v-if="itemsVisible">
            <ul>
                <li @click="handleItemClick(null)" v-if="placeholder" class="placeholder">{{ placeholder }}</li>
                <li v-for="(item, index) in localItems" :key="index"
                    @click="handleItemClick(itemKey ? item[itemKey] : index, $event)"
                    :title="itemValue ? item[itemValue] : item">{{
                        itemValue ? item[itemValue] : item }}</li>
            </ul>
        </div>
    </div>
</template>


<script>
export default {
    name: 'Selection',
    props: {
        items: Object,
        placeholder: String,
        itemKey: String,
        itemValue: String,
        size: String,
        top: Boolean,
        styleClass: String,
        width: {
            type: String,
            default: 'auto'
        },
        value: [Number, String],
        readonly: {
            type: Boolean,
            default: false
        },
        edit: {
            type: Boolean,
            default: false
        },
        remote: String
    },
    data() {
        return {
            localItems: this.items,
            inputValue: '',
            itemsVisible: false,
            blurTimeoutID: null,
            isFocus: false,
            remoteTimeoutID: null
        }
    },
    computed: {
        key: {
            get() {
                return this.value
            },
            set(val) {
                this.inputValue = this.localItems[val]
            }
        }
    },
    mounted() {
        if (this.remote && this.value) {
            this.remoteQuery({ key: this.value }, true)
        } else {
            this.setInputValue(this.value)
        }
    },
    methods: {
        setInputValue(key) {
            this.inputValue = this.localItems[key]
        },
        handleFocus(event) {
            this.destroyBlurTimeout()
            if (!this.readonly) {
                this.itemsVisible = true
                if (this.remote && !this.localItems) {
                    this.remoteQuery({ kw: event.target.value }, false)
                }
            }
        },
        destroyBlurTimeout() {
            if (this.blurTimeoutID != null) {
                clearTimeout(this.blurTimeoutID)
            }
            this.blurTimeoutID = null
        },
        handleBlur() {
            this.destroyBlurTimeout()
            this.blurTimeoutID = setTimeout(() => {
                this.itemsVisible = false
                this.setInputValue(this.value)
            }, 200)
        },
        handleInput(event) {
            if (this.remote) {
                this.remoteQuery({ kw: event.target.value }, false)
            } else if (event.target.value == '') {
                this.localItems = this.items
            } else {
                let tempItems = {}
                for (let key in this.items) {
                    if (this.items[key].indexOf(event.target.value) != -1) {
                        tempItems[key] = this.items[key]
                    }
                }
                this.localItems = tempItems
            }
        },
        handleItemClick(key) {
            this.destroyBlurTimeout()
            this.itemsVisible = false
            this.setInputValue(key)
            this.$emit('handleItemClick', key)
            this.$emit('input', key)
        },
        remoteQuery(params, isSetInputValue) {
            this.destroyBlurTimeout()
            this.remoteTimeoutID = setTimeout(() => {
                this.fetchItems(params, isSetInputValue)
            }, 500)
        },
        fetchItems(params, isSetInputValue) {
            this.$api.get(this.remote, { params: params }).then(res => {
                if (res.data.success) {
                    this.localItems = res.data.data
                    if (isSetInputValue) {
                        this.setInputValue(this.value)
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        },
    },
    watch: {
        value(val) {
            if (this.remote && this.value) {
                this.remoteQuery({ key: val }, true)
            } else {
                this.setInputValue(val)
            }
        },
        items(val) {
            this.localItems = val
            this.setInputValue(this.value)
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/selection.scss';
</style>